import React from 'react'
import { Alert, Col, Container, Row } from 'reactstrap'
import {
    GiArcheryTarget
} from "react-icons/gi"
import {
    MdVisibility
} from "react-icons/md"

export const About = () => {
    return (

        <Container className='mt-5 '>
            <Row >
                <Col md="12" className="justify-content-md-center"  >

                    <h3 className='mt-3'>About Us</h3>
                    <p className='mt-3'>For over a decade HASCO has been rendering unwavering client satisfaction across the Middle East, Africa and India in COLDROOM and REFRIGERATION sector. <br />HASCO, with its headquarters in the UAE, has an eminent  team of highly accomplished engineers and proficient technicians who employ futuristic technologies to ensure exceptionally outstanding products.</p>

                    <Row className='m-5 p-5 '>
                        <Col md="6" className='p-0' >       <Alert className='text-center cardBlocks' style={{ backgroundColor: "#4fc3f7", height: "350px", width: '100%', border: "0px", borderRadius: "0px" }} >
                            <MdVisibility size={70} color="#fff" style={{ height: 120 }} />
                            <h3 className="alert-heading  " style={{ color: "#fff" }}>
                                Our Vision
                            </h3>
                            <p style={{ color: "#fff" }}>
                                To truly become an inimitable organisation across the Asian continent by providing the most advanced and unparalleled value added services and superior products which accentuates the righteousness and respectability of the company.
                            </p>
                        </Alert></Col>
                        <Col md="6" className='p-0 cardBlocks'>
                            <img src="MissionBanner.jpg" class="img-fluid" alt="im" style={{ height: "350px", width: '100%' }} />
                        </Col>
                    </Row>
                    <Row className='m-5 p-5'>
                        <Col md="6" className='p-0 cardBlocks'>
                            <img src="TargetBanner.JPG" class="img-fluid" alt="im" style={{ height: "350px", width: '100%' }} />
                        </Col>
                        <Col md="6" className='p-0' >       <Alert className='text-center cardBlocks ' style={{ backgroundColor: "#81C784", height: "350px", width: '100%', border: "0px", borderRadius: "0px" }} >
                            <GiArcheryTarget size={70} color="#fff" style={{ height: 120 }} />
                            <h3 className="alert-heading  " style={{ color: "#fff" }}>
                                Our Mission
                            </h3>
                            <p style={{ color: "#fff" }}>
                                To provide unsurpassed customer service in the multifaceted dimensions of refrigeration, utilizing premium quality materials with legitimate price.
                            </p>
                        </Alert></Col>
                    </Row>
                </Col>
            </Row>
        </Container>


    )
}
